/* Poppins Fonts */
@font-face {
  font-family: "poppins-black";
  src: url("./Poppins/poppins-black-webfont.woff") format("woff2"),
    url("./Poppins/poppins-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-black-italic";
  src: url("./Poppins/poppins-blackitalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-bold";
  src: url("./Poppins/poppins-bold-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-bold-italic";
  src: url("./Poppins/poppins-bolditalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-extra-bold";
  src: url("./Poppins/poppins-extrabold-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-extra-bold-italic";
  src: url("./Poppins/poppins-extrabolditalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-extra-light";
  src: url("./Poppins/poppins-extralight-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-extra-light-italic";
  src: url("./Poppins/poppins-extralightitalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-extralightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-light";
  src: url("./Poppins/poppins-light-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-light-italic";
  src: url("./Poppins/poppins-lightitalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-medium";
  src: url("./Poppins/poppins-medium-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-regular";
  src: url("./Poppins/poppins-regular-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-semibold";
  src: url("./Poppins/poppins-semibold-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-semibold-italic";
  src: url("./Poppins/poppins-semibolditalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-semibolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "poppins-thin";
  src: url("./Poppins/poppins-thin-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-thin-italic";
  src: url("./Poppins/poppins-thinitalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-medium-italic";
  src: url("./Poppins/poppins-mediumitalic-webfont.woff2") format("woff2"),
    url("./Poppins/poppins-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
