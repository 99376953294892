@import "../../scss/base.scss";

.loader-wrapper {
  background: url("../../assets/images/backgroundImage.webp") 50% fixed;
  background-size: cover;
  height: 100vh;

  .spinnerContainer {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(darken($jum-theme, 40%), 0.75);
  }

  .loading-indicator:before {
    content: "";
    background: $blackColor;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }

  .loading-indicator:after {
    content: "\f1ce";
    font-family: FontAwesome;
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: $whiteColor;
    text-align: center;
    font-weight: 100;
    font-size: 4rem;
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
  }
}