@import "bootstrap/scss/bootstrap";
@import "./variables";
@import "./mixins.scss";
@import "./rtl";
@import "./general";

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

body,
html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  min-height: 100vh;
  background: url("../assets/images/backgroundImage.webp") 10% fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

ul {
  padding: 0;
}

.main-wrapper {
  height: calc(100vh - 100px);
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  min-height: 100%;
  background: rgba(darken($jum-theme, 40%), 0.75);
}

.authBtn {
  background: $jum-theme;
  color: $whiteColor;
  margin: 1rem 0;
}

.red {
  color: $redColor !important;
}

.text-red {
  color: $redColor;
  cursor: pointer;
}

.text-jum {
  color: $jum-theme;
}
.input-error {
  border-color: $dangerColor !important;
}

button {
  border: none;
  outline: none;

  &:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input:-internal-autofill-selected {
  appearance: none !important;
  background-image: unset !important;
  background-color: $transparentColor !important;
}
