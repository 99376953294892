@import "./variables";
@import "./mixins.scss";

// Add you general and shared styles here
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

iframe {
  display: none !important;
}

.error {
  @include FontMedium($dangerColor);
  font-size: 0.7em;
  position: absolute;
  bottom: -20px;
}
// button {
//   all: unset;
// }

.header {
  width: 100%;
  background-color: $transparentColor;
  transition: all 0.5s ease-out;
}

.full-width-header {
  padding: 0 70px;
}

.appRow {
  margin-left: -15px;
  margin-right: -15px;
}

.noFound {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

section {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
}

.page-title {
  @include FontMedium($whiteColor);
}

.flex-container {
  display: flex;
  align-items: center;
  width: 100%;

  @include mobileScreen {
    flex-direction: column;
  }
}

.justify-space {
  justify-content: space-between;
}

.text-white {
  color: $whiteColor;
}

.toast-error {
  background: $redColor;
}

.toast-success {
  background: $successColor;
}
