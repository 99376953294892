@import "./variables";

@mixin FontRegular($color) {
    font-family: $fontRegular;
    font-weight: 400;
    font-style: normal;
    color: $color;

}

@mixin FontMedium($color) {
    font-family: $fontMedium;
    font-weight: 500;
    font-style: normal;
    color: $color;
}

@mixin FontSemiBold($color) {
    font-family: $fontSemiBold;
    font-weight: 600;
    font-style: normal;
    color: $color;

}

@mixin FontBold($color) {
    font-family: $fontBold;
    font-weight: 700;
    font-style: normal;
    color: $color;

}

@mixin FontExtraBold($color) {
    font-family: $fontExtraBold;
    font-weight: 800;
    font-style: normal;
    color: $color;

}

@mixin mobileScreen {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

/* Small screen devices (600px and above) */
@mixin tabletScreen {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

/* Medium screen devices (768px and above) */
@mixin ipadScreens {
    @media (min-width:#{$ipad}) {
        @content;
    }
}

/* Big screen devices (889px and above) */
@mixin laptopScreen {
    @media (min-width: #{$laptop}) {
        @content;
    }
}

/* Extra big screen devices (1200px and above) */
@mixin largeScreen {
    @media (min-width: #{$desktop}) {
        @content;
    }
}